<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="advanced_search"
                  class="mr-1"
              >
                <feather-icon
                    icon="SearchIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-success"
                  @click="exportExcel"
                  class="mr-1"
              >
                <feather-icon
                    icon="DownloadIcon"
                    class="mr-50"
                />
                <span class="align-middle">导出</span>
              </b-button>

            </div>
          </b-col>
        </b-row>

        <!-- 高级搜索 -->
        <b-card v-if="isShowCard">
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition">
            <b-row>
              <!--编号-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="编号"
                    label-for="change_no"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="change_no"
                      size="sm"
                      v-model="condition.change_no"
                      placeholder="请输入编号"
                  />
                </b-form-group>
              </b-col>
              <!--仓库名称-->
              <b-col md="3">
                <modal-select
                    label="仓库名称"
                    type="input"
                    v-on:change="fromChildren($event,['warehouse_name','warehouse_id'],condition)"
                    :params="['warehouse_name','warehouse_id']"
                    modalName="仓库"
                    placeholder="点击选择仓库"
                    v-model="condition.warehouse_name"
                >
                </modal-select>
              </b-col>
              <!--转换前商品-->
              <b-col md="3">
                <modal-select
                    label="转换前商品"
                    type="input"
                    v-on:change="fromChildren($event,['product_name','product_id'],condition)"
                    :params="['product_name','product_id']"
                    modalName="商品"
                    placeholder="点击选择商品"
                    v-model="condition.product_name"
                >
                </modal-select>
              </b-col>
              <!--转换后商品-->
              <b-col md="3">
                <modal-select
                    label="转换后商品"
                    type="input"
                    v-on:change="fromChildren2($event,condition)"
                    :params="['product_name','product_id']"
                    modalName="商品"
                    placeholder="点击选择商品"
                    v-model="condition.changedproductname"
                >
                </modal-select>
              </b-col>
              <!--申请原因-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="申请原因"
                    label-for="reason"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="reason"
                      size="sm"
                      v-model="condition.reason"
                      placeholder="请输入申请原因"
                  />
                </b-form-group>
              </b-col>
              <!--创建时间-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="添加时间"
                    label-for="create_time"
                    label-size="sm"
                >
                  <flat-pickr
                      v-model="condition.create_time"
                      class="form-control"
                      :config="rangeConfig"
                      placeholder="请选择添加时间"
                  />
                </b-form-group>
              </b-col>
              <!--创建人-->
              <b-col md="3">
                <modal-select
                    label="创建人"
                    type="input"
                    v-on:change="fromChildren($event,['full_name','user_id'],condition)"
                    :params="['full_name','user_id']"
                    modalName="创建人"
                    placeholder="点击选择创建人"
                    v-model="condition.full_name"
                >
                </modal-select>
              </b-col>

              <!--查询、重置按钮-->
              <b-col
                  cols="12"
                  md="12"
                  class=" demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </div>

      <xy-tab
          :tabs="getCodeOptions('stock_change_apply')"
          :statusValue="status"
          v-on:emitTabs="function($event) {tabStatus = $event}"
      ></xy-tab>
      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->
        <template #cell(id)="data">
          #{{ data.item.id }}
        </template>

        <template #cell(status)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('stock_change_apply', data.item.status)}`"
          >
            {{ getCodeLabel('stock_change_apply', data.item.status) }}
          </b-badge>
        </template>

        <template #cell(reason)="data">
          <div>
            {{ data.item.reason.substring(0, 3) }}
            <feather-icon
                v-if="data.item.reason.length > 3"
                icon="EyeIcon"
                size="21"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.html="
              '<div class=text-left>'+
              '<br>'+data.item.reason+
              '</div>'"
                variant="success"
            />
          </div>
        </template>

        <template #cell(product_id)="data">
          {{ data.item.ext.oldProduct.name }}
          <feather-icon
              icon="CpuIcon"
              size="21"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="success"
              @click="function () {
                      stockOccupied.warehouse_id=data.item.warehouse_id
                      stockOccupied.product_id=data.item.product_id
                      stockOccupied.stock_id=data.item.stock_id
                      $refs['modal-stock-lock'].show()
                      $forceUpdate()
                      }"
          />
        </template>

        <template #cell(changedproductid)="data">
          {{ data.item.ext.newProduct.name }}
        </template>

        <template #cell(add_time)="data">
          {{ toDate(data.item.add_time) }}
        </template>

        <template #cell(modify_time)="data">
          {{ toDate(data.item.modify_time) }}
        </template>

        <template #cell(creator)="data">
          {{ getCodeLabel('user', data.item.creator) }}
        </template>

        <template #cell(updator)="data">
          {{ getCodeLabel('user', data.item.updator) }}
        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
              @click="changeState(data.item)"
          >
            <b-badge
                pill
                :variant="`light-$ {getCodeColor('state', data.item.state)}`"

            >
              {{ getCodeLabel('state', data.item.state) }}
            </b-badge>
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >
            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="changeStatus(data.item,2,'提交审核')" v-if="data.item.status == 1">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">提交审核</span>
            </b-dropdown-item>

            <b-dropdown-item @click="changeStatus(data.item,3,'审核通过')" v-if="data.item.status == 2&&[14,17,18,5,6].includes(user.role_id)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">审核通过</span>
            </b-dropdown-item>

            <b-dropdown-item @click="changeStatus(data.item,4,'审核失败')" v-if="data.item.status == 2&&[14,17,18,5,6].includes(user.role_id)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">审核失败</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="[3,4].includes(data.item.status)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">无菜单</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <b-modal
        id="modal-stock-lock"
        ref="modal-stock-lock"
        no-close-on-backdrop
        cancel-title="取消"
        centered
        size="xl"
        title="锁库单据"
    >
      <stock-occupied-list
          :warehouse_id="stockOccupied.warehouse_id"
          :product_id="stockOccupied.product_id"
          :stock_id="stockOccupied.stock_id"
      >

      </stock-occupied-list>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor} from '@core/utils/filter'
import stockchangeapplyUseList from './stockchangeapplyUseList'
import stockchangeapplyStore from './stockchangeapplyStore'
import {useToast} from "vue-toastification/composition";
import XyTab from "@/views/components/xy/XyTab";
import {getUserData} from "@/auth/utils";
import Ripple from "vue-ripple-directive";
import StockOccupiedList from "@/views/apps/stockoccupied/StockOccupiedList.vue";
import axios from "@/libs/axios";
import ModalSelect from "@/views/components/modal/ModalSelect";

export default {
  components: {
    StockOccupiedList,
    XyTab,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ModalSelect,
  },
  directives: {
    Ripple,
  },
  data(){
    return{
      status:2,
      user:{},
      stockOccupied:{},
      rangeConfig: {
        maxDate: 'today',
      },
      isShowCard: false,
    }
  },
  methods: {
    changeState: function (data) {
      const state = 1 - data.state
      store.dispatch('stockchangeapply/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
  },
  setup() {
    const toast = useToast()
    // Register module
    if (!store.hasModule('stockchangeapply')) store.registerModule('stockchangeapply', stockchangeapplyStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('stockchangeapply')) store.unregisterModule('stockchangeapply')
    })

    const changeStatus = function (params, status, information) {
      if (confirm("是否" + information)) {
        store.dispatch('stockchangeapply/changeStatus', {id: params.change_id, status: status}).then(res => {
          if (res.data.code == 0) {
            toast.success("操作成功")
            refetchData()
          } else {
            toast.error(res.data.data)
          }
        })
      }
    }

    const fromChildren = function (params, modal, item) {
      for (let i = 0; i < modal.length; i++) {
        item[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()
    }

    const fromChildren2 = function (params, item) {
      item.changedproductid = params.product_id
      item.changedproductname = params.product_name
      this.$forceUpdate()
    }

    const advanced_search = function () {
      this.isShowCard = !this.isShowCard
    }

    const searchByCondition = function () {
      refetchData()
    }

    const resetCondition = function () {
      this.condition = {}
      refetchData();
    }

    const exportExcel = function (){

      const params = {
        status:tabStatus.value,
        change_no:condition.value.change_no,
        warehouse_id:condition.value.warehouse_id,
        product_id:condition.value.product_id,
        changedproductid:condition.value.changedproductid,
        reason:condition.value.reason,
        create_time:condition.value.create_time,
        creator:condition.value.user_id
      }
      axios({
        url: '/api/exportexcelfile/exportStockChangeApply',
        method: 'get',
        params: params,
        responseType: 'blob',
      }).then(res => {
        toast.success('导出成功')
        let data = new Blob([res.data], {type: 'application/vnd.ms-excel'})   //接收的是blob，若接收的是文件流，需要转化一下
        let filename = '库存箱规转换申请单' + toTime(new Date().getTime() /1000)
        /*-------------*/
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = URL.createObjectURL(data)
        link.setAttribute('download', filename + '.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      tabStatus,
      condition,
      // UI
    } = stockchangeapplyUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      tabStatus,
      fromChildren,
      fromChildren2,
      condition,
      advanced_search,
      searchByCondition,
      resetCondition,
      exportExcel,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,
      toDate,
      changeStatus,
    }
  },
  created() {
    this.user = getUserData()
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
