import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function stockchangeapplyUseList() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'change_id', label: 'ID', sortable: true },
    { key: 'change_no', label: '编号', sortable: true },
    { key: 'warehouse_name', label: '仓库', sortable: true },
    { key: 'stock_id', label: '转换前stockId', sortable: true },
    { key: 'product_id', label: '转换前商品', sortable: true },
    { key: 'box_quantity', label: '转换前单箱标准数量', sortable: true },
    { key: 'qty', label: '转换前商品库存(扣除锁库)', sortable: true },
    { key: 'oldsubqty', label: '转换前商品 需转换数量', sortable: true },
    { key: 'changedproductid', label: '转换后商品', sortable: true },
    { key: 'changedbox_quantity', label: '转换后单箱标准数量', sortable: true },
    { key: 'changedqty', label: '转换后商品增加的数量', sortable: true },
    { key: 'reason', label: '申请原因', sortable: true },
    { key: 'status', label: '状态', sortable: true },
    // { key: 'state', label: '状态', sortable: true },
    { key: 'add_time', label: '创建时间', sortable: true },
    { key: 'creator', label: '创建人', sortable: true },
    { key: 'modify_time', label: '修改时间', sortable: true },
    { key: 'updator', label: '修改人', sortable: true },
        { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('change_id')
  const isSortDirDesc = ref(true)
  const tabStatus = ref(2)
  const condition = ref({})

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey,tabStatus], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    store
      .dispatch('stockchangeapply/search', {
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
        status:tabStatus.value,
        change_no:condition.value.change_no,
        warehouse_id:condition.value.warehouse_id,
        product_id:condition.value.product_id,
        changedproductid:condition.value.changedproductid,
        reason:condition.value.reason,
        create_time:condition.value.create_time,
        creator:condition.value.user_id
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals

        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '库存箱规转换申请单列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,
    tabStatus,
    condition,
    refetchData,

    // Extra Filters
  }
}
